<template>
  <div class="check-scan">
    <v-row dense>
      <v-col cols="12" md="12" lg="12" style="padding: 5px 20px">
        <div class="p-3">
          <v-btn :loading="btnLoadingSave" class="btn-save-change mr-3" @click="saveChange">
            {{ $t('schedule_by_week_day.create') }}
          </v-btn>
          <v-btn class="btn-save-change btn-danger" @click="deleteChecked()">
            {{ $t('schedule_by_week_day.delete') }}
          </v-btn>
        </div>

        <v-sheet class=" pa-10 label-input" width="100%" height="auto" color="white"
          style="display: flex;justify-content: center;align-items: center;box-shadow: 0 0.3rem 2rem rgb(0 0 0 / 10%);padding-bottom: 20px;">
          <table class="table">
            <tr>
              <th>{{ $t('schedule_by_week_day.table.week_day') }}</th>
              <th>{{ $t('schedule_by_week_day.table.start_time') }}</th>
              <th>{{ $t('schedule_by_week_day.table.clock_in') }}</th>
              <th>{{ $t('schedule_by_week_day.table.end_time') }}</th>
              <th>{{ $t('schedule_by_week_day.table.clock_out') }}</th>
              <th></th>
            </tr>
            <tr v-for="(item, index) in listChecked" :key="index">
              <td>{{ item.week_day }}</td>
              <td>{{ item.start_in }}</td>
              <td>{{ item.check_in_before }}</td>
              <td>{{ item.start_out }}</td>
              <td>{{ item.check_out_after }}</td>
              <td class="text-center">
                <button @click="editChecked(item)" class="btn btn-warning mr-3"><i class="fas fa-pencil-alt"></i></button>
                <button @click="deleteChecked(item.id)" class="btn mr-3"><i class="fas fa-trash text-danger"></i></button>
              </td>
            </tr>
          </table>
        </v-sheet>
      </v-col>
    </v-row>
    <UpdateChecked :schedule="checkedItem" :visible="modalEditScheduleDetail" @success="fetchCheckedScan"
      @change="(val) => (modalEditScheduleDetail = val)" />
    <DeleteChecked :visible="modalDelScheduleDetail" :schedule_detail_id="delete_id" :delete_status="delete_status"
      @change="(val) => modalDelScheduleDetail = val" @start="isLoading = true" @success="fetchCheckedScan" />

    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import UpdateChecked from "@/components/CheckedScan/Modal/UpdateChecked";
import DeleteChecked from "@/components/CheckedScan/Modal/DeleteChecked";
import Loading from "@/components/Loading";

export default {
  name: "index",
  components: {
    UpdateChecked,
    DeleteChecked,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      weekDays: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN",],
      week_days: "",
      btnLoadingSave: false,
      schedule_detail_id: this.$route.params.schedule_detail_id,
      delete_id: "",
      delete_status: 'all',
      listChecked: [],
      modalEditScheduleDetail: false,
      modalDelScheduleDetail: false,
      deleteList: {},
      checkedItem: {},
    };
  },
  methods: {
    saveChange() {
      this.btnLoadingSave = true;
      this.$axios
        .post(`company/checked-scan`, {
          week_day: this.weekDays,
          check_in_schedule_detail_id: this.schedule_detail_id,
        })
        .then((res) => {
          if (res) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.btnLoadingSave = false;
            this.fetchCheckedScan();
          }
        })
        .catch((error) => {
          if (error && error.response.data.message) {
            this.$notification.ShowErrors(
              "top-right",
              "danger",
              3000,
              `${error.response.data.message}`
            );
          }
          this.btnLoadingSave = false;
        });
    },
    fetchCheckedScan() {
      this.isLoading = true;
      this.$axios
        .get(`company/checked-scan`, {
          params: {
            check_in_schedule_detail_id: this.schedule_detail_id,
          },
        })
        .then((res) => {
          this.listChecked = res.data.data;
          this.isLoading = false;
        }).catch((error) => {
          this.isLoading = false;
        });
    },
    editChecked(item) {
      this.checkedItem = item;
      this.modalEditScheduleDetail = true;
    },
    deleteChecked(id = 'all') {
      this.delete_status = id;
      this.delete_id = id == 'all' ? this.schedule_detail_id : id;
      this.modalDelScheduleDetail = true;
    }
  },
  created() {
    this.fetchCheckedScan();
  },
};
</script>

<style scoped lang="scss">
table {
  border-collapse: collapse;
  width: 100%;
  font-family: $font-family;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.btn-warning {
  color: rgb(255, 186, 82) !important;
}

.btn-danger {
  background-color: red !important;
}
</style>
