<template>
  <v-dialog
      v-model="modalEditScheduleDetail"
      persistent
      width="800"
      @close="setModalEditScheduleDetail(false)"
      transition="scroll-x-transition"
  >
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete" style="float: right">
        <v-btn icon depressed @click="setModalEditScheduleDetail(false)">
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>
      <div class="main-container">
        <div class="main-content">
          <div class="section-schedule-detail">
            <div class="schedule-content-detail">
              <div class="schedule-detail-header">
                <h2>
                  <span><i class="fal fa-alarm-clock"></i></span
                  >ແກ້ໄຂຂໍ້ມູນ
                </h2>
              </div>
              <v-form class="from-schedules">
                <v-row>
                  <v-col cols="12" md="12" class="pt-10">
                    <label class="label-input">
                      ມື້ເຂົ້າວຽກ
                    </label>
                    <v-text-field
                        dense
                        outlined
                        placeholder="Name.."
                        v-model="schedule.week_day"
                        readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <label class="label-input"
                    >{{
                        $t("scheduleDetail.checkin")
                      }}<span class="text-show-error">{{
                          server_errors.start_in
                        }}</span></label
                    >
                    <date-picker
                        class="time--picker"
                        v-model="start_in"
                        style="width: 100%"
                        type="time"
                        placeholder="Start Check In"
                    ></date-picker>
                  </v-col>
                  <v-col cols="6" md="6">
                    <label class="label-input"
                    >{{
                        $t("scheduleDetail.checkin")
                      }}<span class="text-show-error">{{
                          server_errors.check_in_before
                        }}</span></label
                    >
                    <date-picker
                        class="time--picker"
                        v-model="check_in_before"
                        style="width: 100%"
                        type="time"
                        placeholder="Check In Before"
                    ></date-picker>
                  </v-col>
                  <v-col cols="6" md="6" class="mt-8">
                    <label class="label-input"
                    >{{
                        $t("scheduleDetail.checkout")
                      }}<span class="text-show-error">{{
                          server_errors.start_out
                        }}</span></label
                    >
                    <date-picker
                        class="time--picker"
                        v-model="start_out"
                        style="width: 100%"
                        type="time"
                        placeholder="Start Cehck Out"
                    ></date-picker>
                  </v-col>
                  <v-col cols="6" md="6" class="mt-8">
                    <label class="label-input"
                    >{{
                        $t("scheduleDetail.checkout")
                      }}<span class="text-show-error">{{
                          server_errors.check_out_after
                        }}</span></label
                    >
                    <date-picker
                        class="time--picker"
                        v-model="check_out_after"
                        style="width: 100%"
                        type="time"
                        placeholder="Check Out After"
                    ></date-picker>
                  </v-col>
                  <v-col class="mt-5" cols="6" md="6">
                    <label class="label-input">{{
                        $t("scheduleDetail.late")
                      }}</label>
                    <v-text-field
                        type="number"
                        class="input-number"
                        outlined
                        dense
                        placeholder="Late After.."
                        v-model="schedule.late_after"
                        :hint="`${server_errors.late_after}`"
                        persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="mt-5" cols="6" md="6">
                    <label class="label-input">{{
                        $t("scheduleDetail.absence")
                      }}</label>
                    <v-text-field
                        type="number"
                        class="input-number"
                        outlined
                        dense
                        placeholder="Absence After.."
                        v-model="schedule.absense_after"
                        :hint="`${server_errors.absense_after}`"
                        persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="mb-10">
                    <v-btn
                        class="btn-save-change"
                        :loading="btnLoading"
                        @click="saveChange"
                    >
                      {{ $t("scheduleDetail.save") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";

export default {
  components: {
    DatePicker,
  },
  props: {
    visible: {
      default: false,
    },
    schedule: {
      default: {},
    },
    icon: {
      default: "mdi-close",
      type: String,
    },
  },

  data() {
    return {
      modalEditScheduleDetail: false,
      btnLoading: false,
      start_in: "",
      check_in_before: "",
      start_out: "",
      check_out_after: "",
      server_errors: {
        start_in: "",
        check_in_before: "",
        start_out: "",
        check_out_after: "",
        late_after: "",
        absense_after: "",
      },
    };
  },
  methods: {

    setModalEditScheduleDetail(isVisible) {
      this.modalEditScheduleDetail = isVisible;
      this.$emit("change", isVisible);
    },
    formatSchedule() {
      this.start_in = moment(this.schedule.start_in, "HH:mm:ss").toDate();
      this.check_in_before = moment(
          this.schedule.check_in_before,
          "HH:mm:ss"
      ).toDate();
      this.start_out = moment(this.schedule.start_out, "HH:mm:ss").toDate();
      this.check_out_after = moment(
          this.schedule.check_out_after,
          "HH:mm:ss"
      ).toDate();
    },

    saveChange() {
      const item = {
        start_in: moment(this.start_in).format("HH:mm:ss"),
        check_in_before: moment(this.check_in_before).format("HH:mm:ss"),
        start_out: moment(this.start_out).format("HH:mm:ss"),
        check_out_after: moment(this.check_out_after).format("HH:mm:ss"),
        late_after: this.schedule.late_after,
        absense_after: this.schedule.absense_after,
      };

      this.btnLoading = true;
      const checkInSchedule = this.$route.params.schedule_id;
      this.$axios
          .put(
              `company/checked-scan/${this.schedule.id}`,
              item
          )
          .then((res) => {
            if (res.data.code === 200) {
              setTimeout(() => {
                this.$notification.OpenNotification_AddItem_OnSuccess(
                    "top-right",
                    "primary",
                    3000
                );
              }, 300);
              this.btnLoading = false;
            }
            this.setModalEditScheduleDetail(false);
            this.$emit("success");
          })
          .catch((error) => {
            if (error.response.status === 422) {
              const obj = error.response.data.errors;
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
              }
            }
            this.btnLoading = false;
            this.setModalEditScheduleDetail(false);
          });
    },
  },
  watch: {
    visible(val) {
      this.modalEditScheduleDetail = val;
    },
    schedule(value) {
      this.start_in = moment(value.start_in, "HH:mm:ss").toDate();
      this.check_in_before = moment(value.check_in_before, "HH:mm:ss").toDate();
      this.start_out = moment(value.start_out, "HH:mm:ss").toDate();
      this.check_out_after = moment(value.check_out_after, "HH:mm:ss").toDate();
    },
  },
  created() {
    this.formatSchedule();
  },
};
</script>

<style scoped lang="scss">
.section-schedule-detail {
  width: 100%;
  height: auto;
  // background-color: red;
  display: flex;
  flex-direction: column;
  align-items: center;

  .schedule-content-detail {
    margin: 0;
    border: 0;
    width: 600px;
    height: 800px;
    //  background-color: yellow;
    padding: 20px;
    position: relative;

    .from-schedules {
      width: 100%;
      margin-top: 40px;
    }
  }

  .schedule-detail-header {
    width: 100%;
    height: auto;
    background-color: red;

    h2 {
      display: flex;
      justify-content: center;
      align-self: center;
      font-family: $font-family;
      position: absolute;
      font-size: 30px;
      color: #363636;

      span {
        margin-right: 10px;
      }
    }
  }
}

.text-show-error {
  font-size: 12px;
  color: red;
}
</style>


