<template>
  <div>
    <v-row justify="center">

      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5 ">
            <span class="lfont">ຢືນຢັນການລຶບ ?</span>
          </v-card-title>

          <v-card-text>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="deleteChecked()">
              Ok
            </v-btn>

            <v-btn color="green darken-1" text @click="closeModal()">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      default: false
    },
    schedule_detail_id: {},
    delete_status: ""
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit('change', false);
    },
    deleteChecked() {
      this.$emit('change', false);
      this.$emit('start');
      if (this.delete_status == 'all') {
        this.$axios.delete(`company/checked-scan/${this.schedule_detail_id}/all`).then((res) => {
          this.$emit('success');
        }).catch((error) => {
          this.$emit('success');
        });
      } else {
        this.$axios.delete(`company/checked-scan/${this.schedule_detail_id}`).then((res) => {
          this.$emit('success');
        }).catch((error) => {
          this.$emit('success');
        });
      }


    }
  },
  watch: {
    visible(val) {
      this.dialog = val;
    }
  }
}
</script>

<style lang="scss" scoped></style>